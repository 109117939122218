@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

:root {
  --main-font: 'Inter', sans-serif;
  --main-color: #39ff53;
  --background-color: #171717;
  --background-darkblue: #101010;
  --hover-color: rgba(83, 255, 57, 0.5);
  --white-color: #FFFFFF;
  --black-color: #000000;
  --description-color: #D2D0D0;
  --green-color: #4AFF6B;
  --copy-ip-button-background: rgba(70, 255, 57, 0.7);
  --stat-icon-background-2: rgba(57, 255, 73, 0.5);
}

body, html {
  background-color: var(--background-dark);
  color: var(--text-light);
  font-family: var(--main-font), serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;

  * {
    transition: 0.2s;

    z-index: 1;
  }

  .container {
    display: flex;
    flex-direction: column;
    max-width: 100vw !important;
    padding: 0 !important;
  }

  .custom-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    h1 {
      max-width: 80vw;
    }
  }

  .custom-header-big {
    width: 80%;
    background: linear-gradient(90deg, var(--background-gradient-start) 0%, var(--background-gradient-end) 100%);
    padding: 40px 20px;
    margin-bottom: 2rem;
    z-index: 1;
  }

  .custom-header-small {
    width: 60%;
    background: linear-gradient(90deg, var(--lighter-gradient-start) 0%, var(--lighter-gradient-end) 100%);
    padding: 25px 100px;
    margin-bottom: 2rem;
    z-index: 1;
  }

  .custom-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: max-content;
    padding: 2rem 0 2rem 0;
  }

  .background-video {
    position: absolute;

    z-index: 0;
    object-position: center;
    pointer-events: none;

    min-width: 100%;
    max-height: 100vh;

    display: flex;
    justify-content: center;
    & img,
    & video {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .info-box {
    display: flex;
    flex-direction: column;
    background-color: var(--navy-opacity);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    transition: all 0.3s;
    width: max-content;

    &:hover {
      scale: 1.05;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
      border: 4px solid var(--muted-blue);
    }

    .icon {
      margin-left: auto;
      margin-right: auto;
      padding: 4px;
      font-size: 36px;
    }

    & .buttons {
      & .alert-success {
        display: none;
        position: absolute;
        margin-top: 4rem;
        gap: 4px;
        font-size: 12px !important;
        padding: 10px !important;
        &.active {
          display: flex;
        }
      }
    }
  }

  p, li {
    line-height: 1.5;
  }

  p {
    font-size: calc(0.6vw + 0.5rem) !important;
    font-weight: 200;
    margin-bottom: 0 !important;
  }

  h1, h2, h3, h4 {
    margin-bottom: 0;
  }

  h1 {
    font-size: calc(0.6vw + 1rem) !important;
    font-weight: 800 !important;
  }

  h2 {
    font-size: calc(0.6vw + 0.9rem) !important;
    font-weight: 600 !important;
  }

  h3 {
    font-size: calc(0.6vw + 0.8rem) !important;
    font-weight: 600 !important;
  }

  h4 {
    font-size: calc(0.6vw + 0.7rem) !important;
    font-weight: 600 !important;
  }

  i {
    font-size: 1.5rem;
    color: var(--muted-blue);
  }

  .smallText {
    font-size: 80%;
  }

  .custom-list {
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  a {
    color: #c7c7c7 !important;
    text-decoration: none;

    &:hover {
      color: #ffffff !important;
      text-decoration: none;
    }
  }

  & button {
    background: var(--copy-ip-button-background);
    border: 2px solid var(--main-color);
    border-radius: 3px;
    padding: calc(0.2rem + 0.2vw) calc(0.4rem + 0.4vw);
    color: var(--white-color);
    font-size: calc(0.2rem + 1vw);
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      filter: brightness(150%);
    }
  }
}
