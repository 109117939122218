#Team {
  width: 100vw;
  background-image: url("https://wallpapercave.com/wp/wp7749573.jpg");

  .team-backdropFilter {
    display: flex;
    justify-content: center;
    width: 100vw;
    backdrop-filter: blur(10px) brightness(0.2);

    .team-overview {
      display: flex;
      flex-direction: column;
      margin: 20px;
      padding: 10px;
      border-radius: 8px;

      .team-rank {
        margin-bottom: 20px;
        text-align: center;

        ul {
          list-style: none;
          padding: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 1rem;

          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 10px;
            margin: 5px 0;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(255,255,255,0.1);
            gap: 1rem;

            width: 10rem;
            height: 10rem;

            h5 {
              font-size: 0.8rem; /* Initial value */
              line-height: 1;
              white-space: nowrap; /* Prevents text from wrapping */
              transform: scale(1);
              transform-origin: center;
            }

            div {
              display: flex;
              flex-direction: column;
            }

            img {
              width: 6rem;
              height: 6rem;
            }
          }
        }
      }
    }
  }
}


