#HeaderNav {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem;
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
  font-family: 'Inter', serif !important;
  z-index: 10;

  * {
    z-index: 10;
  }

  .HeaderNavLogo {
    a {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      padding: 6px;
      gap: 8px;
      order: 0;
      flex-grow: 0;
      width: max-content;
      text-decoration: underline transparent;
    }

    img {
      width: 42px;
      height: 42px;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    h1 {
      font-weight: 900;
      font-size: 1.2rem !important;
      color: #FFFFFF;
    }
  }

  .nav-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.1));
    }
  }

  .nav-item {
    .active {
      font-weight: 500;
      filter: brightness(1.2);

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        width: 40px;
        height: 3px;
        border-radius: 5px;
        background: var(--main-color);
      }
    }
  }

  .miniLogo {
    width: 12px;
    height: 12px;
  }

  .navbar-toggler {
    padding: 5px 5px !important;

    .navbar-toggler-icon {
      color: white;
    }
  }

  #navbarToggle {
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100vw;
    overflow: hidden;
    height: auto;
    left: 0;
    z-index: 10;
    background: var(--background-color);
    flex-direction: column;
    filter: drop-shadow(0 10px 4px rgba(0, 0, 0, 0.25));
    transition: 0.2s;

    * {
      overflow: hidden;
      position: relative;
    }
  }


  .HeaderNavGroupLeft {
    z-index: 11;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0 4vw;

    .navbar-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
    }

    .nav-link {
      width: 100vw;

      &:hover {
        background: var(--hover-color);
      }
    }

    .HeaderNavGroupLeft {
      width: max-content;
      gap: 4px;
      z-index: 11;
    }

    .HeaderNavLogo h1 {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    .HeaderNavGroupLeft {
      gap: 4px;
    }

    .HeaderNavSearchbar {
      display: none;
    }

    .HeaderNavLoggedIn {
      gap: 0;
    }
  }
}
