#Footer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding: 1rem;
    width: 100%;
    overflow: hidden;

    background: var(--background-color);
}

#Footer *{
    z-index: 10;
}

.FooterContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FooterContent iframe {
    max-width: 80vw;
}

.FooterContentTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.FooterContentTitle div{
    display: flex;
    flex-direction: row;
}

.FooterContentTitle img{
    width: 50px;
}

.FooterContentTitle h1{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    color: #FFFFFF;

    flex: none;
    order: 1;
    flex-grow: 0;

    margin: 10px;
}

.FooterContentTitle p{
    font-style: normal;
    font-weight: 275;
    font-size: 24px;
    line-height: 24px;

    color: #FFFFFF;

    flex: none;
    order: 1;
    flex-grow: 0;
    width: calc(20% + 200px);
    text-align: center;
}

.FooterCopyright{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #777E91;

    flex: none;
    order: 0;
    flex-grow: 0;
}